* {
  font-family: Helvetica, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
}

.gaznat-container {
  box-sizing: border-box;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;

  .gaznat-header {
    flex: 0 1 auto;
  }

  .gaznat-content {
    flex: 1 1 auto;
    overflow: auto;
  }

  .gaznat-tab {
    flex: 0 1 auto;
    min-height: 8vh;
    padding-top: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  }

  .gaznat-timeline {
    h5 {
      cursor: pointer;

      &:hover {
        color: #2980b9;
      }
    }

    .gaznat-timeline-bubble {
      position: absolute;
      left: 0;
      top:50%;
      transform: translateY(-50%);
      height: 30px;
      width: 30px;
      margin: 0 20px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 2px solid rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .gaznat-footer {
    background-color: #f5f5f5;
    color: #000;
    flex: 0 1 auto;
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.16), 1px 0 2px rgba(0, 0, 0, 0.23);

    .gaznat-col-bordered {
      border-right: 1px solid #cecece;
      height: 100% !important;
    }

    .gaznat-same-height {
      height: 100% !important;
    }
  }

  .gaznat-overflow {
    height: 100%;
    max-height: 100%;
    width: 100%;
    overflow: auto;
  }

  .gaznat-sidenav {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  }
}

.gaznat-signin-title {
  font-size: 5em;
  text-transform: uppercase;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
}

.gaznat-signin-title-thin {
  font-weight: 100;
}

.gaznat-signin-smalltitle {
  font-weight: 300;
}

.gaznat-signin-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 450px;
  background-image: url("../../images/auth/gaznat-signin.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.logo {
  float: left;

  * {
    max-height: 75px;
  }
}

.gaznat-signin-logo {
  width: 200px;
  height: 200px;
  background-image: url("../../images/white-label/gaznat.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gaznat-sil-logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 300px;
  height: 160px;
  background-image: url("../../images/white-label/sil.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gaznat-sil-logo-small {
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
  height: 80px;
  background-image: url("../../images/white-label/sil.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.gaznat-powered {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
}

.gaznat-signin-logo-small {
  position: absolute;
  left: 0;
  top: 0;
  width: 85px;
  height: 85px;
  background-image: url("../../images/white-label/gaznat.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gaznat-panel-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  .gaznat-panel {
    display: flex;
    width: 100%;
    height: 200px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    padding: 1rem;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    font-size: 2em;
    transition: all 0.3s, transform 0.3s;
    color: #aeaeae;

    &.logout {
      background-color: lightcoral;
      color: white;
    }

    &.account {
      background-color: #3D4856;
      color: white;
    }

    i {
      font-size: 4em;
    }

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      color: black;
    }
  }
}

tr.text-bold{
  font-weight: bold;
}

.table{
  td{
    position: relative;
  }
}

.gaznat-content {
  table tr {

    td{
      position: relative;
    }

    td:first-child,
    th:first-child {
      min-width: 160px !important;
      max-width: 160px !important;
      white-space: pre-wrap !important;
    }
  }

  table tbody tr {
    th:last-child {
      min-width: 150px !important;
      max-width: 150px !important;
    }
  }
}

.gaznat-table-tot {
  background-color: rgba(41, 128, 185, 0.3);
}

.gaznat-table-input {
  border: none;
  outline: none;
  background-color: transparent !important;
  text-align: right;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.gaznat-table-useredit-s td:nth-child(n + 2) {
  background-color: rgba(41, 128, 185, 0.16);

  input {
    display: block;
    min-width: 70px;

    &:focus {
      background-color: transparent;
    }
  }
}

.gaznat-table-useredit td:nth-child(n + 3) {
  background-color: rgba(41, 128, 185, 0.16);

  input {
    display: block;
    min-width: 70px;

    &:focus {
      background-color: transparent;
    }
  }
}

.gaznat-table-param-s td:nth-child(n + 2) {
  background-color: rgba(245, 205, 121, 0.23);

  input {
    display: block;
    min-width: 70px;

    &:focus {
      background-color: transparent;
    }
  }
}

.gaznat-table-param td:nth-child(n + 3) {
  background-color: rgba(245, 205, 121, 0.23);

  input {
    display: block;
    min-width: 70px;

    &:focus {
      background-color: transparent;
    }
  }
}

.gaznat-input-param {
  background-color: rgba(245, 205, 121, 0.23);

  &:focus {
    background-color: rgba(245, 205, 121, 0.23);
  }
}

.gaznat-input-useredit {
  background-color: rgba(41, 128, 185, 0.16);
}

.btn {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  &:focus {
    outline: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  }
}

.gaznat-context-menu {
  position: fixed;
  z-index: 100;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.gaznat-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
  margin-right: 20px;
}

.gaznat-switch-small {
  height: 14px;
  width: 30px;
  margin-bottom: -3px;
  margin-right: 5px;
  margin-left: 5px;
}

.gaznat-switch input {
  display: none !important;
  outline: none;
  border:none;
}

.gaznat-slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.gaznat-slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

.gaznat-slider-small:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 10px;
  left: 2px;
  position: absolute;
  transition: 0.4s;
  width: 10px;
}

input:checked+.gaznat-slider {
  background-color: #2980b9;
}

input:checked+.gaznat-slider:before {
  transform: translateX(26px);
}

input:checked+.gaznat-slider-small:before {
  transform: translateX(16px);
}

.gaznat-slider.gaznat-round {
  border-radius: 34px;
}

.gaznat-slider.gaznat-round:before {
  border-radius: 50%;
}

.gaznat-footer-table {
  tr td:nth-child(even) {
    text-align: right;
    padding-right: 10px;
  }
}

.table tr td.gaznat-table-input-disabled {
  background-color: #cecece;
}

.small-logo {
  max-height: 30px;
}

.gaznat-unit-col {
  min-width: 160px !important;
  max-width: 160px !important;
}

.gaznat-year-wrapper {
  position: fixed;
  width: auto !important;
  z-index: 10;
  top: 225px;

  &.gaznat-year-wrapper-sp {
    top: 150px
  }

  &.gaznat-year-wrapper-sp2 {
    top: 100px
  }

  .gaznat-year-shadow {
    background: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .16), 0 1px 2px rgba(0, 0, 0, .23);
    padding: 10px;
  }
}

.gaznat-button-word {
  background-color: #5B82B4 !important;
}

.gaznat-button-excel {
  background-color: #1BA657 !important;
}

.gaznat-button-wrapper {
  position: relative;
  z-index: 100;
}

body {
  background-color: white;
}

tfoot {
  * {
    font-weight: bold;
  }
}

.gaznat-button-fix-z {
  position: relative;
  z-index: 10000;
}

.gaznat-price-source{
  position: absolute;
  right:2px;
  top: 2px;
  // background-color: #2980b9;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  box-shadow: 0 1px 2px rgba(0,0,0,.16), 0 1px 2px rgba(0,0,0,.23);
  border:1px solid rgba(0,0,0,.13);
  color:#212121;
}